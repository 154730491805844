@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffff;
    overflow: hidden;
    z-index: -1; /* Assure que la div est en arrière-plan */
}

.background span {
    width: 1vmin;
    height: 1vmin;
    border-radius: 1vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 50;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #6e29b;
    top: 8%;
    left: 7%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 17vw 16vh;
    box-shadow: 2vmin 0 1.0658545831732893vmin currentColor;
}
.background span:nth-child(1) {
    color: #64e5ab;
    top: 78%;
    left: 19%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 8vw -14vh;
    box-shadow: 2vmin 0 0.9864469981934851vmin currentColor;
}
.background span:nth-child(2) {
    color: #e867b8;
    top: 37%;
    left: 3%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -15vw -23vh;
    box-shadow: 2vmin 0 0.5594388809672359vmin currentColor;
}
.background span:nth-child(3) {
    color: #583C87;
    top: 97%;
    left: 79%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -4vw -8vh;
    box-shadow: -2vmin 0 0.2898371463396623vmin currentColor;
}
.background span:nth-child(4) {
    color: #d6e06d;
    top: 27%;
    left: 69%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -8vw 18vh;
    box-shadow: -2vmin 0 0.48872431663955895vmin currentColor;
}
.background span:nth-child(5) {
    color: #583C87;
    top: 96%;
    left: 43%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -6vw 16vh;
    box-shadow: -2vmin 0 0.764493605220198vmin currentColor;
}
.background span:nth-child(6) {
    color: #64e5ab;
    top: 45%;
    left: 94%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 6vw 5vh;
    box-shadow: 2vmin 0 1.0790937923201744vmin currentColor;
}
.background span:nth-child(7) {
    color: #d6e06d;
    top: 19%;
    left: 12%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -15vw 8vh;
    box-shadow: -2vmin 0 0.6715849804044811vmin currentColor;
}
.background span:nth-child(8) {
    color: #e867b8;
    top: 15%;
    left: 37%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -2vw 5vh;
    box-shadow: -2vmin 0 1.0019929587432792vmin currentColor;
}
.background span:nth-child(9) {
    color: #4336b9;
    top: 94%;
    left: 3%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 18vw -13vh;
    box-shadow: 2vmin 0 0.6099151062142616vmin currentColor;
}
.background span:nth-child(10) {
    color: #4336b9;
    top: 78%;
    left: 54%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -7vw 20vh;
    box-shadow: 2vmin 0 0.2616171509347811vmin currentColor;
}
.background span:nth-child(11) {
    color: #583C87;
    top: 66%;
    left: 100%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 21vw 24vh;
    box-shadow: 2vmin 0 0.3948568357118385vmin currentColor;
}
.background span:nth-child(12) {
    color: #4336b9;
    top: 21%;
    left: 56%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -1vw -12vh;
    box-shadow: -2vmin 0 0.9792005951002196vmin currentColor;
}
.background span:nth-child(13) {
    color: #64e5ab;
    top: 58%;
    left: 99%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -12vw -10vh;
    box-shadow: -2vmin 0 0.8862590391920242vmin currentColor;
}
.background span:nth-child(14) {
    color: #e867b8;
    top: 8%;
    left: 56%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -3vw -8vh;
    box-shadow: 2vmin 0 0.5897141011703806vmin currentColor;
}
.background span:nth-child(15) {
    color: #d6e06d;
    top: 94%;
    left: 90%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 9vw 25vh;
    box-shadow: 2vmin 0 0.3302760433009464vmin currentColor;
}
.background span:nth-child(16) {
    color: #d6e06d;
    top: 57%;
    left: 59%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 13vw -2vh;
    box-shadow: -2vmin 0 0.9079101219764438vmin currentColor;
}
.background span:nth-child(17) {
    color: #4336b9;
    top: 37%;
    left: 97%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 8vw 12vh;
    box-shadow: 2vmin 0 0.31710482064989476vmin currentColor;
}
.background span:nth-child(18) {
    color: #64e5ab;
    top: 61%;
    left: 18%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -12vw -16vh;
    box-shadow: -2vmin 0 0.2692164265415966vmin currentColor;
}
.background span:nth-child(19) {
    color: #4336b9;
    top: 87%;
    left: 88%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 20vw 20vh;
    box-shadow: 2vmin 0 0.7673141368328547vmin currentColor;
}
.background span:nth-child(20) {
    color: #d6e06d;
    top: 28%;
    left: 13%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 6vw -13vh;
    box-shadow: 2vmin 0 0.5230957140017611vmin currentColor;
}
.background span:nth-child(21) {
    color: #64e5ab;
    top: 59%;
    left: 3%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 4vw 8vh;
    box-shadow: 2vmin 0 0.8712218594964996vmin currentColor;
}
.background span:nth-child(22) {
    color: #4336b9;
    top: 38%;
    left: 39%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -20vw -13vh;
    box-shadow: -2vmin 0 1.2294268400073343vmin currentColor;
}
.background span:nth-child(23) {
    color: #4336b9;
    top: 32%;
    left: 60%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -4vw -12vh;
    box-shadow: 2vmin 0 0.9441281437080058vmin currentColor;
}
.background span:nth-child(24) {
    color: #64e5ab;
    top: 53%;
    left: 49%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 4vw 18vh;
    box-shadow: -2vmin 0 1.1470598805269716vmin currentColor;
}
.background span:nth-child(25) {
    color: #6e29b;
    top: 14%;
    left: 66%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -15vw 14vh;
    box-shadow: 2vmin 0 0.8579195056586486vmin currentColor;
}
.background span:nth-child(26) {
    color: #64e5ab;
    top: 67%;
    left: 89%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 9vw -17vh;
    box-shadow: -2vmin 0 0.3322391043640309vmin currentColor;
}
.background span:nth-child(27) {
    color: #d6e06d;
    top: 24%;
    left: 93%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 24vw 3vh;
    box-shadow: -2vmin 0 1.1760768372127872vmin currentColor;
}
.background span:nth-child(28) {
    color: #64e5ab;
    top: 4%;
    left: 90%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -12vw 21vh;
    box-shadow: 2vmin 0 1.1795878758929694vmin currentColor;
}
.background span:nth-child(29) {
    color: #4336b9;
    top: 5%;
    left: 92%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 19vw -6vh;
    box-shadow: -2vmin 0 0.6449487089489303vmin currentColor;
}
.background span:nth-child(30) {
    color: #4336b9;
    top: 39%;
    left: 61%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -13vw -16vh;
    box-shadow: -2vmin 0 0.39383831047185014vmin currentColor;
}
.background span:nth-child(31) {
    color: #583C87;
    top: 15%;
    left: 42%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -20vw -3vh;
    box-shadow: -2vmin 0 1.1560391926008091vmin currentColor;
}
.background span:nth-child(32) {
    color: #e867b8;
    top: 79%;
    left: 2%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 10vh;
    box-shadow: 2vmin 0 0.599940727477895vmin currentColor;
}
.background span:nth-child(33) {
    color: #d6e06d;
    top: 50%;
    left: 84%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -20vw 12vh;
    box-shadow: 2vmin 0 0.6954383633326349vmin currentColor;
}
.background span:nth-child(34) {
    color: #64e5ab;
    top: 75%;
    left: 77%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 16vh;
    box-shadow: 2vmin 0 0.6391882684311985vmin currentColor;
}
.background span:nth-child(35) {
    color: #4336b9;
    top: 68%;
    left: 63%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -2vw 24vh;
    box-shadow: 2vmin 0 1.2005349925119522vmin currentColor;
}
.background span:nth-child(36) {
    color: #64e5ab;
    top: 78%;
    left: 46%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 15vw -3vh;
    box-shadow: 2vmin 0 0.4379349432445143vmin currentColor;
}
.background span:nth-child(37) {
    color: #d6e06d;
    top: 67%;
    left: 37%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 0vw 4vh;
    box-shadow: -2vmin 0 0.6057704334753751vmin currentColor;
}
.background span:nth-child(38) {
    color: #583C87;
    top: 63%;
    left: 80%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -6vw -14vh;
    box-shadow: 2vmin 0 0.445680660805837vmin currentColor;
}
.background span:nth-child(39) {
    color: #64e5ab;
    top: 79%;
    left: 11%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 2vw 15vh;
    box-shadow: -2vmin 0 0.2910847992324257vmin currentColor;
}
.background span:nth-child(40) {
    color: #64e5ab;
    top: 3%;
    left: 98%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -24vw -21vh;
    box-shadow: -2vmin 0 1.1638416017689726vmin currentColor;
}
.background span:nth-child(41) {
    color: #6e29b;
    top: 14%;
    left: 37%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 3vw 23vh;
    box-shadow: 2vmin 0 0.962773537076729vmin currentColor;
}
.background span:nth-child(42) {
    color: #e867b8;
    top: 9%;
    left: 86%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 24vw -21vh;
    box-shadow: -2vmin 0 1.1933345889377827vmin currentColor;
}
.background span:nth-child(43) {
    color: #583C87;
    top: 48%;
    left: 32%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -16vw -15vh;
    box-shadow: 2vmin 0 0.6250348669331867vmin currentColor;
}
.background span:nth-child(44) {
    color: #e867b8;
    top: 56%;
    left: 11%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 22vw 2vh;
    box-shadow: 2vmin 0 0.9785044031022303vmin currentColor;
}
.background span:nth-child(45) {
    color: #d6e06d;
    top: 12%;
    left: 40%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 24vw 12vh;
    box-shadow: 2vmin 0 0.60260082546355vmin currentColor;
}
.background span:nth-child(46) {
    color: #d6e06d;
    top: 50%;
    left: 59%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 25vw 24vh;
    box-shadow: 2vmin 0 1.056954712768108vmin currentColor;
}
.background span:nth-child(47) {
    color: #6e29b;
    top: 43%;
    left: 95%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -5vw 2vh;
    box-shadow: -2vmin 0 0.9627287808501743vmin currentColor;
}
.background span:nth-child(48) {
    color: #64e5ab;
    top: 93%;
    left: 100%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: 24vw 25vh;
    box-shadow: -2vmin 0 1.2200984894353633vmin currentColor;
}
.background span:nth-child(49) {
    color: #d6e06d;
    top: 29%;
    left: 61%;
    animation-duration: 6s;
    animation-delay: -1s;
    transform-origin: -22vw 25vh;
    box-shadow: 2vmin 0 1.0198778144704232vmin currentColor;
}
