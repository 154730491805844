
.user_error{
  display: none;
}
@media only screen and (max-width: 855px) {

/*   .user {
     display: none; 

  }
  .user_error{
    display: block;
  } */
}