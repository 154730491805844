.wrapper-class {
  border: 5px solid #ccc;
}
.editor-class {
  background-color: rgba(253, 253, 253, 0.14);
  max-height: 500px;
  overflow-y: auto;
}
.toolbar-class {
  border: 1px solid #ccc;
  background-color: #0c84ff;
}

.preview {
  padding: 1rem;
  margin-top: 1rem;
  background-color: #0c0c0d;
}
