.body-element {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes arrows {
    0%,
    100% {
        color: black;
        transform: translateY(0);
    }
    50% {
        color: #3AB493;
        transform: translateY(20px);
    }
}

.element {
    --delay: 0s;
    animation: arrows 1s var(--delay) infinite ease-in;
}